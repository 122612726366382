<template>
    <FormTemplate v-if="form" :form="form">
        <div class="row">
            <div class="col-lg-6">
                <div class="form-group">
                    <label class="label-for-xl" for="inputFirstName">First Name</label>
                    <b-form-input
                        type="text" id="inputFirstName" class="form-control form-control-xl"
                        placeholder="enter first name"
                        v-model="form.data.firstName"
                        :state="form.states.firstName"
                    />
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <label class="label-for-xl" for="inputLastName">Last Name</label>
                    <b-form-input
                        type="text" id="inputLastName" class="form-control form-control-xl"
                        placeholder="enter last name"
                        v-model="form.data.lastName"
                        :state="form.states.lastName"
                    />
                </div>
            </div>
        </div>
        <b-form-group class="label-for-xl" label="E-Mail Address" label-for="inputEmail">
            <b-form-input
                type="text" id="inputEmail" class="form-control form-control-xl"
                placeholder="enter the email address"
                v-model="form.data.email"
                :state="form.states.email"
            />
            <b-form-invalid-feedback v-if="form.errors">{{ form.errors.email }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group class="label-for-xl" label="Password" label-for="inputPassword">
          <b-input-group>
            <b-form-input
                type="text" id="inputPassword" class="form-control form-control-xl"
                placeholder="set password"
                v-model="form.data.password"
                :state="form.states.password"
            />
            <b-input-group-append>
              <b-button variant="primary" @click="generatePassword()"><BIconLock/></b-button>
            </b-input-group-append>
            <b-form-invalid-feedback v-if="form.errors">{{ form.errors.password }}</b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
    </FormTemplate>
</template>

<script>
import {BIconLock} from 'bootstrap-vue';
import {Form} from '@/common/utils/parnekt';
import {randomString} from '@/common/utils/common';
import FormTemplate from '@/common/components/Form/Form';

export default {
    components: {
        FormTemplate,
        BIconLock
    },
    data(){
        return {
            form: null
        };
    },

    methods: {
      generatePassword(){
        this.form.set('password', randomString(10));
      }
    },

    mounted(){
        this.form = new Form({firstName: "",lastName: "",email: "",password: ""},{
            onSubmit: (form) => {
                return this.$api.post('user',{
                    data: {
                        locale: "en",
                        firstName: form.get('firstName'),
                        lastName: form.get('lastName'),
                        email: form.get('email'),
                        password: form.get('password'),
                    }
                });
            },
            onSuccess: (form) => {
                form.resetData();
                this.$store.dispatch('Notification/addNotification', {event: "onTeamUpdate"});
            }
        });
    }
};
</script>
