<template>
    <div id="team">
      <PageMenu :breadcrumbs="breadcrumbs" :page="page"/>
      <div class="page-with-menu">
          <b-row>
            <b-col cols="4">
              <SidebarManager active="users.manager.user.create" :user="user"/>
            </b-col>
            <b-col cols="8">
              <b-card>
                <FormUserCreate/>
              </b-card>
            </b-col>
          </b-row>
      </div>
    </div>
</template>

<script>
import PageMenu from '@/common/components/PageMenu';
import SidebarManager from './../../components/Sidebar.Manager';
import FormUserCreate from './../../components/forms/Form.User.Create';
export default {
  props: ['user'],
  components: {
    PageMenu,
    SidebarManager,
    FormUserCreate
  },
  data(){
      return {
        page: {
            title: "Create new user",
        },
        breadcrumbs: [
            {
                title: "Dashboard",
                url: this.$router.resolve({name: "app.dashboard"}).href
            },
            {
                title: "My Team",
                url: this.$router.resolve({name: "users.manager.browse"}).href
            },
            {
                title: "Create New User",
            }
        ],

      };
  },

  methods: {

  },

  mounted(){

  },
};
</script>
